<template>
  <button
    class="dropdown:block bg-transparent border-none"
    role="navigation"
    aria-haspopup="true"
  >
    <div
      class="
        relative
        rounded-md
        bg-transparent
        p-2
        focus:outline-none
        border-0
        text-dokBlue-ultra
        cursor-pointer
        text-base
      "
    >
      <span
        class="
          bg-dokBlue-light
          h-5
          w-5
          rounded-full
          flex
          items-center
          justify-center
          shadow
          absolute
          top-0
          right-0
          font-EffraR
          text-dokBlue-dark
        "
      >
        {{ counterNotification || 0 }}</span
      >
      <i class="far fa-calendar-check fa-2x"></i>
    </div>

    <ul
      class="hidden absolute text-left w-full max-w-370 right-0"
      aria-label="submenu"
    >
      <template v-if="!appointments.length" class="pt-2">
        <img src="/icons/calendar.svg" class="max-h-24" />
        <div class="bg-dokBlue-ultra text-white py-3 text-center font-EffraR">
          <i class="fas fa-info-circle"></i> No Pending Appointments
        </div>
      </template>

      <div
        class="
          py-0
          max-h-300
          overflow-y-scroll overflow-x-hidden
          scrolling-touch
          scroll
          divide-y divide-x-0 divide-gray-300 divide-solid
        "
        v-if="appointments.length"
      >
        <div v-for="(appointment, i) in appointments" :key="i">
          <div class="flex flex-row px-1 py-4">
            <div
              class="
                bg-dokBlue-ultra
                h-16
                w-16
                flex
                items-center
                justify-center
                rounded-full
                mr-5
              "
            >
              <i class="fas fa-calendar-day text-white text-4xl"></i>
            </div>
            <div class="flex flex-col">
              <div class="flex flex-row">
                <span class="text-blackdok text-base mr-3"
                  ><i class="fas fa-calendar-day"></i>
                  {{
                    _moment()(appointment.startAt)
                      .format("DD MMMM")
                      .toUpperCase()
                  }}</span
                >
                <span class="text-blackdok text-base"
                  ><i class="far fa-clock"></i>
                  {{
                    _moment()(appointment.startAt)
                      .format("HH:mm")
                      .toUpperCase()
                  }}</span
                >
              </div>
              <h3 class="text-lg text-blackdok font-semibold my-2">
                {{ appointment.patient_firstName }}
                {{ appointment.patient_lastName }}
              </h3>
              <div class="flex flex-row">
                <span
                  v-if="appointment.categoryId"
                  class="
                    bg-dokBlue-ultra bg-opacity-20
                    text-dokBlue-ultra text-sm text-shadow
                    rounded-full
                    px-3
                    py-1
                    mr-2
                  "
                >
                  {{ appointment.category_title }}
                </span>
              </div>
            </div>
          </div>
          <div class="bg-gray-200 flex justify-between items-center py-5 px-1">
            <div>
              <button
                v-if="appointment.status !== 'TRANSFERED'"
                @click="onProposeClicked(appointment)"
                class="
                  bg-transparent
                  border-0
                  text-gray-500 text-sm
                  xl:text-base
                  font-semibold
                  cursor-pointer
                "
              >
                CHANGER L'HORAIRE
              </button>
            </div>
            <div class="flex gap-x-2">
              <button
                @click="setAppointmentStatus(i, appointment._id, 'REJECTED')"
                class="
                  bg-transparent
                  border-0
                  text-red-600 text-sm
                  xl:text-base
                  font-semibold
                  cursor-pointer
                "
              >
                REFUSER
              </button>
              <button
                @click="setAppointmentStatus(i, appointment._id, 'ACCEPTED')"
                class="
                  bg-transparent
                  border-0
                  text-green-600 text-sm
                  xl:text-base
                  font-semibold
                  cursor-pointer
                "
              >
                ACCEPTER
              </button>
            </div>
          </div>
        </div>
      </div>
    </ul>
  </button>
</template>

<script>
import { mapActions } from "vuex";
import { EventBus } from "@/event-bus";
import moment from "moment";
import { EVENTS_SUBSCRIBE } from "@/graphql/notifications";
export default {
  data() {
    return {
      drop: false,
      appointments: [],
      counterNotification: localStorage.getItem("AppointmentsCounter")
    };
  },
  methods: {
    ...mapActions("agenda", ["INDEX_PENDING_EVENTS", "UPDATE_STATUS_EVENT"]),
    _moment() {
      return moment;
    },
    onProposeClicked(event) {
      if (event._id) {
        this.drop = false;
        EventBus.$emit("ProposEvent", event);
      }
    },
    async onDrop() {
      this.drop = !this.drop;

      let notificationUpdate = JSON.parse(this.counterNotification);
      notificationUpdate.counter = 0;

      localStorage.setItem(
        "AppointmentsCounter",
        JSON.stringify(notificationUpdate)
      );

      this.$forceUpdate();
    },
    async onGetNewAppointments() {
      const { ok, data } = await this.INDEX_PENDING_EVENTS();
      if (ok) {
        this.appointments = data;
        // //console.log(data);
      }
    },
    onLoadMore: function() {
      const limit = this.paginte.skip;
      this.paginte = {
        skip: this.$store.state.listItemsPerPage + limit
      };

      this.$store.dispatch("doctor/GET_PENDING_APPOINTMENTS", {
        paginate: this.paginte,
        onData: this.onData
      });

      this.$vs.loading({ color: "rgb(49, 120, 252)" });
    },
    setAppointmentStatus: async function(index, appointmentId, status) {
      const { ok, data } = await this.UPDATE_STATUS_EVENT({
        _id: appointmentId,
        status
      });

      if (ok) {
        if (status === "ACCEPTED") {
          EventBus.$emit("Push-New-Event-Agenda", data);
        }
        this.appointments.splice(index, 1);
        this.drop = false;
      }
    },
    onData: function({ ok, data, message }) {
      if (ok) {
        // this.counterNotification = _.filter(data, function (o) {
        //   return !o.seenByDoctor;
        // }).length; // This For Counter Notifications

        if (this.paginte.skip != 0) {
          this.appointments = [...this.appointments, ...data];
          if (data.length < this.$store.state.listItemsPerPage) {
            // //console.log("No more notifications appointments");
          }
        } else this.appointments = data;

        this.$vs.loading.close();
      } else {
        console.error(message);
      }
    },
    plusNotifications() {
      if (!this.counterNotification) {
        return localStorage.setItem(
          "AppointmentsCounter",
          JSON.stringify({ counter: 1 })
        );
      }

      let notificationUpdate = JSON.parse(this.counterNotification);
      notificationUpdate.counter++;

      localStorage.setItem(
        "AppointmentsCounter",
        JSON.stringify(notificationUpdate)
      );

      this.$forceUpdate();
    }
  },
  mounted() {
    EventBus.$on("New_Appointment_From_Patient", async data => {
      this.onGetNewAppointments();
      this.plusNotifications();

      await new Howl({
        src: `/audios/notification.mp3`,
        autoplay: true,
        volume: 0.5
      });
    });
  },
  apollo: {
    $subscribe: {
      appointments: {
        query: EVENTS_SUBSCRIBE,
        variables() {
          return {
            userId: this.$auth.getUserId()
          };
        },
        result({ data }) {
          this.onGetNewAppointments();
          // this.appointments = data.pending_appointements;
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.iconbell {
  i {
    font-size: 30px;
  }
}
@media (max-width: 1675px) {
  .iconbell {
    i {
      font-size: 24px;
    }
    .count {
      font-size: 11px;
    }
  }
}
</style>
