<template>
  <div>
    <div class="bg-white flex items-center w-full">
      <!-- Logo Other Actions -->
      <div class="flex justify-start w-1/3">
        <button
          class="block xl:hidden bg-transparent border-0 cursor-pointer"
          @click="onCallSidebar"
        >
          <img src="/svg/align-justify.svg" />
        </button>
        <!-- Button Create New Appointment -->
        <div class="w-full xl:w-auto mb-2 xl:mb-0 hidden xl:block">
          <button
            v-if="$route.name === 'agenda'"
            class="
              text-lg
              bg-dokBlue-ultra
              py-2
              px-5
              text-white
              border-0
              rounded
              shadow
              cursor-pointer
              font-EffraR
              w-full
              flex flex-row
              items-center
              gap-x-2
            "
            @click="emitModalCreateAppointment"
          >
            <i class="far fa-calendar-plus"></i> Créer un Rendez-vous
          </button>
        </div>
      </div>

      <!-- Teleconseltation Doctor With Patient -->
      <div class="flex justify-center w-1/3">
        <!-- Upcomming Video Consultation -->
        <div
          class="
            bg-dokBlue-ultra
            rounded-cu
            shadow-blue
            py-3
            px-5
            w-full
            xl:w-auto
            mb-2
            xl:mb-0
            relative
          "
          v-if="upComming && upComming._id"
        >
          <i
            class="
              fas
              fa-times
              cursor-pointer
              absolute
              right-0
              top-0
              text-white
              m-1
            "
            @click="closeUpComming"
          ></i>
          <div class="flex flex-row w-full justify-between items-center">
            <div class="flex flex-row items-center">
              <img
                :src="$checkAvatar(upComming.user.image)"
                class="
                  h-10
                  w-h-10
                  rounded-full
                  object-cover
                  border border-solid border-white
                "
              />
              <div class="flex flex-col ml-2">
                <h3 class="font-EffraR text-white text-lg">
                  Rendez-vous en téléconsultation avec
                  <b>{{
                    `${upComming.user.firstName} ${upComming.user.lastName}`
                  }}</b>
                </h3>
                <div
                  class="font-EffraR text-white flex flex-row items-center mt-2"
                  v-if="upComming.isOnline"
                >
                  <div
                    class="h-2 w-2 rounded-full bg-rosedok animate-ping mr-2"
                  ></div>
                  <b class="mr-1">{{
                    `${upComming.user.firstName} ${upComming.user.lastName}`
                  }}</b>
                  est dans la sale d'attente
                </div>
              </div>
            </div>
            <div class="flex flex-col ml-5">
              <span
                class="
                  bg-roseopacity
                  w-full
                  h-auto
                  xl:h-6
                  rounded-full
                  flex
                  items-center
                  justify-center
                  font-EffraR
                  px-5
                "
                v-if="
                  upComming.nowIs !== 'start' &&
                    Math.sign(upComming.startsWithin) === 1
                "
              >
                <!-- Counter Down -->
                <vue-countdown
                  :time="upComming.startsWithin * 60000"
                  :transform="transform"
                >
                  <template slot-scope="props">
                    <span class="font-EffraR text-rosedok text-base"
                      >{{ props.minutes }}, {{ props.seconds }}</span
                    >
                  </template>
                </vue-countdown>
                <!-- End Counter -->
              </span>
              <button
                class="
                  bg-rosedok
                  text-white
                  border border-solid border-nnblue
                  font-EffraR
                  mt-2
                  py-2
                  px-2
                  rounded-cu
                  shadow-blue
                  cursor-pointer
                "
                @click="$router.push(`/consultations/${upComming._id}`)"
              >
                Lancer la téléconsultation
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- Notifications & Agenda & Activation Mobile -->
      <div
        class="flex items-center justify-end gap-x-3 w-full xl:w-1/3 relative"
      >
        <activationMobile v-if="!mobileActive"></activationMobile>
        <notifications></notifications>
        <agenda></agenda>
        <user-info></user-info>
      </div>
    </div>

    <!-- Change Date Event -->
    <modal
      :isActive="eventPropos.modal"
      :exitModal="
        () => {
          eventPropos.modal = false;
          eventPropos.data = {};
        }
      "
      :title="
        `${eventPropos.data.patient_firstName} ${
          eventPropos.data.patient_lastName
        } at, ${_moment()(eventPropos.data.startAt).format('DD/MM/YYYY HH:mm')}`
      "
      :min-width="400"
    >
      <div class="px-2">
        <div class="flex flex-col mb-2">
          <label for="date">Choice Date : </label>
          <date-picker
            format="DD/MM/YYYY"
            v-model="PickerDate"
            :lang="DatePickerLocal"
            class="w-full"
            @change="generateHours"
          >
            <span slot="icon-calendar"><i class="fas fa-search"></i></span>
          </date-picker>
        </div>

        <div
          class="grid gap-3 grid-cols-5 mb-5"
          v-if="availableHoursLoader === false"
        >
          <div
            v-for="(hour, index) in availableHours"
            :key="index"
            class="
              text-center
              py-2
              rounded-cu
              border border-solid border-gray-300
              shadow
            "
            :class="
              hour.isAvailable
                ? ` ${
                    _moment()(eventPropos.data.startAt).format('HH:mm') ===
                    hour.formatedHour
                      ? 'bg-dokBlue-ultra text-white cursor-pointer'
                      : 'bg-white text-dokBlue-ultra cursor-pointer'
                  }
                `
                : 'bg-gray-200 text-gray-600 cursor-not-allowed'
            "
            @click="setHour(hour.formatedHour)"
          >
            <!-- @click="setHour(hour.formatedHour)" -->

            <span class="block font-EffraR"> {{ hour.formatedHour }}</span>
          </div>
        </div>
        <loader
          v-if="availableHoursLoader === 'LOADING'"
          class="flex justify-center w-full"
        ></loader>

        <!-- Save Action -->
        <button
          v-if="saveButton"
          @click="changeDate"
          class="
            bg-transparent
            hover:bg-dokBlue-ultra
            text-dokBlue-ultra
            hover:text-white
            border-2 border-solid border-dokBlue-ultra
            font-EffraR
            w-full
            py-2
            px-1
            rounded-cu
            transition
            delay-75
            duration-150
            ease-in-out
            cursor-pointer
          "
        >
          Save change
        </button>
      </div>
    </modal>
  </div>
</template>

<script>
import moment from "moment";
import { EventBus } from "@/event-bus";
import { mapActions, mapGetters } from "vuex";
import notifications from "@/layouts/components/navbar/layouts/notifications/notifications";
import agenda from "@/layouts/components/navbar/layouts/notifications/agenda";
import activationMobile from "@/layouts/components/navbar/layouts/notifications/activationMobile";
import userInfo from "@/layouts/components/navbar/layouts/userInfo";
import modal from "@/views/global-components/modal";
import loader from "@/views/global-components/loader";

// Picker Date
import DatePicker from "vue2-datepicker";
import { fr } from "vue2-datepicker/locale/fr";
import "vue2-datepicker/index.css";

// Countdown
import VueCountdown from "@chenfengyuan/vue-countdown";

export default {
  data() {
    return {
      PickerDate: moment(new Date()).clone(),
      DatePickerLocal: fr,
      availableHours: [],
      availableHoursLoader: "STRING",
      saveButton: false,
      upComming: {
        countDown: null
      },
      eventPropos: {
        modal: false,
        data: {}
      },
      mobileActive: this.$auth.getUserInfo().isMobileVerified
    };
  },
  mounted() {
    EventBus.$on("ProposEvent", event => {
      this.eventPropos.modal = true;
      this.eventPropos.data = event;
    });

    // Listen For Owner Waiting Rooom
    EventBus.$on("isJoinToWaitingRoom", payload => {
      this.upCommingTele();
      //console.log("PATIENT IN WAITING ROOM");
    });
  },
  created() {
    this.upCommingTele();
  },
  methods: {
    ...mapActions("agenda", ["GENERATED_HOURS", "UPDATE_DATE_EVENT"]),
    transform(props) {
      if (props.minutes < 1) {
        this.upComming.nowIs = "start";
        this.$forceUpdate();
      }

      Object.entries(props).forEach(([key, value]) => {
        const digits = value < 10 ? `0${value}` : value;
        const word = value < 2 ? key.replace(/s$/, "") : key;
        props[key] = `${digits} ${word}`;
      });

      return props;
    },
    onCallSidebar() {
      EventBus.$emit("Call-Side-Bar");
    },
    async onStartVideoCall() {
      let _this = this;
      let stream = null;

      try {
        stream = await navigator.mediaDevices.getUserMedia({
          video: true,
          audio: true
        });

        if (!_this.upComming.conversationId)
          return alert("Conversation Not Selected");
        EventBus.$emit("collectionVideoCall", {
          token: "",
          conversationId: _this.upComming.conversationId,
          onVideoCallClicked: () => {}
        });
      } catch (err) {
        //console.log(err);
        _this.permissionCamera = true;
      }
    },
    closeUpComming() {
      this.$confirm({
        message: `Êtes-vous sûr(e)?`,
        button: {
          no: "Non",
          yes: "Oui"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            this.upComming = {};
          }
        }
      });
    },
    async upCommingTele() {
      this.$store.dispatch("agenda/GET_UPCOMING_RDV", {
        onData: ({ ok, data }) => {
          if (ok) {
            this.upComming = data;
            this.$forceUpdate();
          }
        }
      });
    },
    setHour(hour) {
      let formHour = moment(hour, "HH:mm");
      this.eventPropos.data.startAt = moment(this.PickerDate)
        .set("minute", formHour.format("mm"))
        .set("hour", formHour.format("HH"));

      if (!this.eventPropos.data.startAt.isValid())
        return (this.saveButton = false);

      return (this.saveButton = true);
    },
    async changeDate() {
      const data = await this.UPDATE_DATE_EVENT({
        id: this.eventPropos.data._id,
        toDate: this.eventPropos.data.startAt
      });

      if (data.ok) {
        this.eventPropos = {
          modal: false,
          data: {}
        };
        this.availableHours = [];
        this.availableHoursLoader = "STRING";
        this.saveButton = false;
      }
    },
    emitModalCreateAppointment() {
      EventBus.$emit("Modal_Create_Appointment");
    },
    async generateHours() {
      if (
        this.eventPropos.data.categoryId === null ||
        !moment(this.eventPropos.data.startAt).isValid()
      )
        return;

      this.availableHoursLoader = "LOADING";
      const { data, ok } = await this.GENERATED_HOURS({
        date: moment(this.eventPropos.data.startAt).format("DD/MM/YYYY"),
        category: this.eventPropos.data.categoryId
      });
      if (ok) {
        this.availableHours = data;
        this.availableHoursLoader = false;
      }
    },
    _moment() {
      return moment;
    }
  },
  components: {
    notifications,
    agenda,
    activationMobile,
    userInfo,
    DatePicker,
    modal,
    loader,
    VueCountdown
  }
};
</script>

<style></style>
