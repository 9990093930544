<template>
  <button
    class="dropdown:block bg-transparent border-none"
    role="navigation"
    aria-haspopup="true"
  >
    <div class="flex flex-row items-center cursor-pointer gap-x-1">
      <img
        :src="$auth.getAvatar()"
        :title="$auth.getFullName()"
        onerror="javascript:this.src='/no-avatar.png'"
        class="rounded-full h-8 xl:h-12 w-8 xl:w-12 mr-1 border border-solid border-gray-400"
      />
      <div class="hidden xl:flex flex-col">
        <h5 class="font-EffraR">{{ $auth.getFullName() }}</h5>
        <div class="flex flex-col gap-y-1">
          <template
            v-if="
              $auth.getUserInfo().landingPage &&
                $auth.getUserInfo().landingPage.specialities.length
            "
          >
            <span class="font-EffraM text-gray-600 text-left">{{
              $auth.getUserInfo().landingPage.specialities[0].name
            }}</span>
          </template>
        </div>
      </div>
    </div>

    <ul
      class="hidden absolute text-left w-full max-w-200 right-0 divide-y divide-x-0 divide-gray-300 divide-solid"
      style="top: 42px;"
      aria-label="submenu"
    >
      <li
        @click="$router.push('/settings')"
        class="py-3 px-1 font-EffraR cursor-pointer text-base text-gray-800 hover:bg-dokBlue-light hover:text-dokBlue-ultra"
      >
        <i class="fas fa-user-cog"></i> Paramètres
      </li>
      <li
        @click="logout"
        class="py-3 px-1 font-EffraR cursor-pointer text-base text-gray-800 hover:bg-dokBlue-light hover:text-dokBlue-ultra"
      >
        <i class="fas fa-door-open"></i> Se déconnecter
      </li>
    </ul>
  </button>
</template>

<script>
export default {
  data() {
    return {
      dropSetting: false
    };
  },
  methods: {
    logout: function() {
      this.$auth.logOut();
      window.location.pathname = "/";
    }
  }
};
</script>

<style lang="scss">
// Dropdown Navbar
.dropdown-custume {
  @apply bg-white;
  @apply origin-top-right;
  @apply absolute;
  @apply mt-2;
  @apply shadow-lg;
  @apply z-1000;
  @apply border-0;
  @apply min-w-370;
  z-index: 9999999999999;
  right: -20px;
  top: 63px;
  &::before {
    content: " ";
    border: solid transparent 20px;
    border-bottom-color: #fff;
    position: absolute;
    top: -40px;
    right: 20px;
  }
}

@media (max-width: 758px) {
  .dropdown-custume {
    @apply max-w-300;
    display: block;
    left: -30px;
    &::before {
      content: " ";
      border: solid transparent 20px;
      border-bottom-color: #fff;
      position: absolute;
      top: -40px;
      right: 230px;
    }
  }
}

@media (max-width: 760px) {
  .dropdown-custume {
    max-width: 260px;
    min-width: 260px;
    display: block;
    left: -80px;
    &::before {
      content: " ";
      border: solid transparent 20px;
      border-bottom-color: white;
      position: absolute;
      top: -40px;
      right: 140px;
    }
  }
}

// settings

.dropdown-customize-setting {
  @apply origin-top-right;
  @apply absolute;
  @apply mt-2;
  @apply shadow-lg;
  @apply max-w-4xl;
  @apply bg-white;
  @apply pt-1;
  @apply border-0;
  z-index: 9999999999999;
  max-width: 360px;
  min-width: 360px;
  right: 0px;
  top: 63px;
  &::before {
    content: " ";
    border: solid transparent 20px;
    border-bottom-color: #fff;
    position: absolute;
    top: -40px;
    left: 200px;
  }
}

@media (max-width: 1279px) {
  .dropdown-customize-setting {
    @apply origin-top-right;
    @apply absolute;
    @apply mt-2;
    @apply shadow-lg;
    @apply max-w-4xl;
    @apply bg-white;
    @apply pt-1;
    @apply border-0;
    z-index: 9999999999999;
    max-width: 360px;
    min-width: 360px;
    right: 0px;
    &::before {
      content: " ";
      border: solid transparent 20px;
      border-bottom-color: #fff;
      position: absolute;
      top: -40px;
      left: 210px;
    }
  }
}

@media (max-width: 640px) {
  .dropdown-customize-setting {
    @apply origin-top-right;
    @apply absolute;
    @apply mt-2;
    @apply shadow-lg;
    @apply bg-white;
    @apply pt-1;
    @apply border-0;
    z-index: 9999999999999;
    max-width: 300px;
    min-width: 230px;
    &::before {
      content: " ";
      border: solid transparent 20px;
      border-bottom-color: #fff;
      position: absolute;
      top: -40px;
      left: 80px;
    }
  }
}
</style>
