<template>
  <button
    class="dropdown:block bg-transparent border-none"
    role="navigation"
    aria-haspopup="true"
  >
    <div
      class="relative rounded-md bg-transparent p-2 focus:outline-none border-0 text-dokBlue-ultra cursor-pointer text-base"
    >
      <span
        class="bg-dokBlue-light h-5 w-5 rounded-full flex items-center justify-center shadow absolute top-0 right-0 font-EffraR text-dokBlue-dark"
      >
        {{ unreadNotificationsCount }}</span
      >
      <i class="far fa-bell fa-2x"></i>
    </div>

    <ul
      class="hidden absolute text-left w-full max-w-370 right-0"
      aria-label="submenu"
    >
      <template v-if="!notifications.length" class="pt-2">
        <div class="bg-dokBlue-ultra text-white py-3 text-center font-EffraR">
          <i class="fas fa-info-circle"></i> No notifications
        </div>
      </template>

      <div
        class="flex items-start px-4 py-3 hover:bg-gray-100 border-0 border-b border-solid border-gray-200 cursor-pointer"
        :class="ntf.status === 'unread' ? 'bg-gray-200' : ''"
        @click="markAsRead(ntf.id)"
        v-for="ntf in notifications"
        :key="ntf.id"
      >
        <i class="far fa-bell fa-2x mr-2 text-dokBlue-ultra"></i>

        <div class="flex flex-col">
          <div id="fromUser" class="text-black flex flex-row justify-between">
            <span class="text-base font-EffraR">{{ ntf.title }}</span>
            <span id="date" class="text-sm font-EffraM text-gray-700">{{
              dateFromNow(ntf.created_at)
            }}</span>
          </div>
          <div id="otherInfo" class="flex flex-row">
            <div
              class="text-dokBlue-ultra text-base font-EffraR"
              v-html="parsUrl(ntf.body)"
            ></div>
          </div>
        </div>
      </div>
    </ul>
  </button>
</template>

<script>
import {
  NOTIFICATIONS_SUBSCRIBE,
  NOTIFICATIONS_UNREAD_SUBSCRIBE,
  NOTIFICATIONS_MARK_AS_READ
} from "@/graphql/notifications";
import moment from "moment";
export default {
  data() {
    return {
      notifications: [],
      drop: false,
      unreadNotificationsCount: 0,
      lastNotification: null
    };
  },
  methods: {
    dateFromNow(date) {
      return moment(date).fromNow();
    },
    setNotifications(notifications) {
      this.notifications = notifications;
      const newestNotification = this.notifications[0];

      if (
        newestNotification &&
        localStorage.getItem("last-notification") !== newestNotification.id
      ) {
        localStorage.setItem("last-notification", newestNotification.id);
        this.$vs.notify({
          time: 8000,
          title: newestNotification.title,
          text: newestNotification.body,
          color: "success",
          iconPack: "feather",
          position: "bottom-right",
          icon: "icon-check"
        });
      }
    },
    parsUrl: function(text) {
      let urlRegex = text.match(/(https?:\/\/[^\s]+)/g);
      if (urlRegex)
        return text.replace(/(https?:\/\/[^\s]+)/g, text => {
          return `<a href="${text}" class="hover:underline" target="_blank">Cliquez ici</a>`;
        });
      return text;
    },
    markAsRead(notificationId) {
      this.$apollo.mutate({
        mutation: NOTIFICATIONS_MARK_AS_READ,
        variables: {
          uuid: notificationId
        }
      });
    }
  },
  apollo: {
    $subscribe: {
      notifications: {
        query: NOTIFICATIONS_SUBSCRIBE,
        variables() {
          return {
            userId: this.$auth.getUserId()
          };
        },
        result(data) {
          this.setNotifications(data.data.notifications);
        }
      },
      unreadNotifications: {
        query: NOTIFICATIONS_UNREAD_SUBSCRIBE,
        variables() {
          return {
            status: "unread"
          };
        },
        result(data) {
          this.unreadNotificationsCount =
            data.data.notifications_aggregate.aggregate.count;
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.iconbell {
  i {
    font-size: 30px;
  }
}
@media (max-width: 1675px) {
  .iconbell {
    i {
      font-size: 24px;
    }
    .count {
      font-size: 11px;
    }
  }
}
</style>
