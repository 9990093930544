<template>
  <button
    class="dropdown:block bg-transparent border-none"
    role="navigation"
    aria-haspopup="true"
  >
    <div
      class="relative rounded-md bg-transparent p-2 focus:outline-none border-0 text-rosDok-ultra cursor-pointer text-base"
    >
      <i class="fas fa-mobile-alt fa-2x"></i>
    </div>

    <ul
      class="hidden absolute text-left w-full max-w-370 right-0 divide-y divide-x-0 divide-gray-300 divide-solid"
      aria-label="submenu"
    >
      <div
        class="py-3 text-center font-EffraR cursor-pointer"
        @click="
          () => {
            this.$router.push({ name: '2fa' });
          }
        "
      >
        <i class="fas fa-mouse animate-pulse mr-2 hidden xl:block"></i>
        Cliquer pour confirmer votre numéro de portable
      </div>
    </ul>
  </button>
</template>

<script>
import { EventBus } from "@/event-bus";

export default {
  data() {
    return {
      drop: false
    };
  },
  methods: {
    onDrop() {
      this.drop = !this.drop;
    }
  }
};
</script>

<style lang="scss" scoped>
.iconbell {
  i {
    font-size: 30px;
  }
}
@media (max-width: 1675px) {
  .iconbell {
    i {
      font-size: 24px;
    }
    .count {
      font-size: 11px;
    }
  }
}
</style>
